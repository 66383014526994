var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('stemble-card',{attrs:{"color":"rgba(11,87,155)","image":"","data-test":"assignment","data-test-id":_vm.assignment.id,"padding":"3"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('s-stack',{staticStyle:{"white-space":"nowrap","overflow":"hidden"},attrs:{"gap":"1","direction":"vertical"}},[_c('s-stack',[_c('p',{staticClass:"font-weight-medium text-h4",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.assignment.name)+" ")]),_c('p',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.assignment.course.name)+" ")])]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('due'))+" "+_vm._s(_vm.formattedDueDate))])],1)]},proxy:true}])},[_c('s-stack',{attrs:{"direction":"horizontal","align":"space-between","valign":"center","wrap":"","gap":"2"}},[_c('div',[_c('v-btn',{staticClass:"no-background-hover ma-0",attrs:{"ripple":false,"elevation":"1","color":"secondary","rounded":"","small":"","href":("/web/courses/" + (_vm.assignment.courseId) + "/assignments/" + (_vm.assignment.id) + "/tasks/first"),"data-test":"assignment-preview-btn"}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"position":"relative","bottom":"1px"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$i('assignment.previewAssignment'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('go-to-assignment'))+" ")],1)],1),_c('s-stack',{attrs:{"direction":"horizontal","gap":"2"}},[_c('v-tooltip',{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"fab":"","x-small":"","color":"secondary","elevation":"2","to":_vm.assignmentRouteTo,"aria-label":"Edit Assignment"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"role":"presentation"}},[_vm._v(_vm._s(_vm.$i('assignment.editAssignment')))])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('editAssignmentButton'))+" ")]),_c('v-tooltip',{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"fab":"","x-small":"","color":"secondary","elevation":"2","href":("/courses/" + (_vm.assignment.courseId) + "/assignments/" + (_vm.assignment.id) + "/grades"),"aria-label":"View Grades"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"role":"presentation"}},[_vm._v("mdi-table-check")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('grades'))+" ")]),_c('v-tooltip',{attrs:{"open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"fab":"","x-small":"","color":"secondary","elevation":"2","href":("/courses/" + (_vm.assignment.courseId) + "/assignments/" + (_vm.assignment.id) + "/extensions"),"aria-label":"View Assignment Extensions"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"role":"presentation"}},[_vm._v(_vm._s(_vm.$i('assignment.extensions')))])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('viewAssignmentExtensions'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }